import Button from "@material-ui/core/Button"
import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import { ContactUrlQuery } from "./__generated__/ContactUrlQuery"

const ContactButton: React.FC = () => {
  const { wpPage } = useStaticQuery<ContactUrlQuery>(graphql`
    query ContactUrlQuery {
      wpPage(databaseId: { eq: 15 }) {
        uri
      }
    }
  `)

  return (
    <Button
      component={Link}
      to={String(wpPage?.uri)}
      variant="contained"
      size="large"
      color="primary"
    >
      neem contact op
    </Button>
  )
}

export default ContactButton
